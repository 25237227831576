export const Config = {
    languages : [
        { id: 1, name: 'English', code: 'en', flag: "https://flagcdn.com/us.svg" },
        { id: 2, name: 'Việt Nam', code: 'vi', flag: "https://flagcdn.com/vn.svg" }
    ],
    phone: "phimhotttcom",
};

export const moviePerPage = {
    movie_pre_generate:10,
    search_take:20,
    banner_take:10,
    hot_take:15,
    trailer_take:15,
    cinema_take:15,
    series_take:15,
    single_take:15,
    present_take:15,
    populary_take:14,
    ramdom_take:8,
    movie_take_by_type:30,
    movie_take_by_category:30,
    movie_take_by_country:30,
    movie_take_by_year:30,
    movie_take_by_search:30,
}

export const LanguagesSupport = ['en', 'vi'];
export const DefaultLanguage = process.env.DEFAULT_LANGUAGE;
